import { useContext, useEffect } from "react";
import { getCurrentUserID } from "../services/firebase";
import { compareTimestamps } from "../utilities/dates";
import { AlertType } from "../components/providers/app-alerts/AppAlerts";
import { usePreviousValue } from "./usePreviousValue";
import useArrivedOnPageTimestamp from "./useArrivedOnPageTimestamp";
import { AppAlertsContext } from "../context/app-alerts-context";
import GameContext from "../context/game-context";
import appContent from "../markdown/app-content";

export default function useNotifyOnCorrectAnswer() {
  const { team } = useContext(GameContext);
  const arrivedAt = useArrivedOnPageTimestamp();
  const previousTeam = usePreviousValue(team);
  const { popAlert, popDismissibleAlert } = useContext(AppAlertsContext);

  useEffect(() => {
    if (
      team?.clueAnswers.length >= 1 &&
      team?.clueAnswers.length !== previousTeam?.clueAnswers.length
    ) {
      const [lastAnswer] = team.clueAnswers.slice(-1);

      // Only show clue answer if it was answered after user arrived
      if (compareTimestamps(lastAnswer.addedAt, arrivedAt) <= 0) {
        if (lastAnswer.answeredBy === getCurrentUserID()) {
          popAlert(appContent.alerts.correctAnswer, AlertType.SUCCESS);
        }
        else {
          // Alerts answered by someone else should be confirmed before being dismissed
          popDismissibleAlert(
            appContent.alerts.correctAnswerByPlayer(team.getTeamMemberName(lastAnswer.answeredBy), lastAnswer.answer),
            AlertType.SUCCESS
          );
        }
      }

    }
  });
}
