import React, {useContext, useEffect, useState} from "react";
import {StringParam, useQueryParam} from "use-query-params";
import {FadeContents, InGameClue, InGameFooter, InlineAlert, LoadingSpinner, Logo, PageLayout} from "../../components/components";
import useCurrentClueLogic from "../../hooks/useCurrentClueLogic";
import {TeamStatus} from "../../entities/team";
import useRecordFinishGameWhenCluesAreAnswered from "../../hooks/useRecordFinishGameWhenCluesAreAnswered";
import useNotifyOnCorrectAnswer from "../../hooks/useNotifyOnCorrectAnswer";
import useNotifyOnNewTeamMember from "../../hooks/useNotifyOnNewTeamMember";
import GameContext from "../../context/game-context";
import useSignUpGuard from "../../hooks/useSignUpGuard";
import useHasAddedPhoneNumberAndEmailGuard from "../../hooks/useHasAddedPhoneNumberAndEmailGuard";
import useNoTeamRedirect from "../../hooks/useNoTeamRedirect";
import useNotifyOnClueMasterAlert from "../../hooks/useNotifyOnClueMasterAlert";
import useNotifyWhenPushedToDifferentClue from "../../hooks/useNotifyWhenPushedToDifferentClue";
import PlayerPresenceAndIdling from "../../app/game/in-game/PlayerPresenceAndIdling";
import HintNudgeModal from "../../app/game/in-game/hint-nudge/HintNudgeModal";
import appContent from "../../markdown/app-content";
import ApplyCustomGameTheme from "../../app/game/shared/apply-custom-game-theme/ApplyCustomGameTheme";
import useGameLogo from "../../hooks/useGameLogo";
import useNotifyOnContentRevealed from "../../hooks/useNotifyOnContentRevealed";
import WrappedUp from "../../app/game/in-game/wrapped-up/WrappedUp";
import {useWaitingRoomGuard} from "../../hooks/useWaitingRoomGuard";
import {useRecordClueStartTimes} from "../../hooks/useRecordClueStartTimes";
import {useRecordPastDisabledClue} from "../../hooks/useRecordPastDisabledClue";
import {useRecordTimeLimitHit} from "../../hooks/useRecordTimeLimitHit";

const InGame = () => {
  const [gameId] = useQueryParam("gameId", StringParam);
  const params = new URLSearchParams({gameId: gameId});
  const {game, team, isLoading} = useContext(GameContext);
  const {clue, status, finishedAllClues, noAssignedTrack, showWrapItUp} = useCurrentClueLogic(game, team);
  const [finishedAlert, setFinishedAlert] = useState(null);
  useSignUpGuard();
  useWaitingRoomGuard();
  useRecordFinishGameWhenCluesAreAnswered();
  useNotifyOnCorrectAnswer();
  useNotifyOnNewTeamMember();
  useNotifyOnClueMasterAlert();
  useNotifyWhenPushedToDifferentClue();
  useNotifyOnContentRevealed(clue);
  useNoTeamRedirect(`/game/join-game?${params.toString()}`);
  useHasAddedPhoneNumberAndEmailGuard(`/game/phone-email-required?${params.toString()}`);
  useRecordClueStartTimes();
  useRecordPastDisabledClue();
  useRecordTimeLimitHit({clue});
  const {smallLogo} = useGameLogo({useGameOnHomePage: false});

  // Handle when the game has finished
  useEffect(() => {
    if (!team || !game) {
      return;
    }

    let endGameMessage = null;
    if (finishedAllClues) {
      // Handle End Game Messages
      if (status === TeamStatus.FINISHED) {
        if (team.isHostedGame)
          endGameMessage = appContent.inGame.finishedGame.hostedMessage;
        else
          endGameMessage = appContent.inGame.finishedGame.unhostedMessage;
      }
    }

    if (endGameMessage) {
      if (finishedAlert?.id !== endGameMessage) {
        setFinishedAlert({
          id: endGameMessage,
          message: endGameMessage,
          variant: "success",
        });
      }
    } else {
      setFinishedAlert(null);
    }
  }, [game, team, status, finishedAllClues, finishedAlert]);

  return <LoadingSpinner isLoading={isLoading} hideChildrenWhenLoading>
    <FadeContents show={!isLoading}>
      <PageLayout footer={<InGameFooter gameId={gameId} finishedGame={finishedAllClues}/>}>
        <Logo id="clue-logo" className="mb-5" src={smallLogo} style={{width: "100%"}}/>
        {finishedAlert && <InlineAlert alert={finishedAlert}/>}
        {showWrapItUp ?
          <WrappedUp/> :
          clue && team && <InGameClue clue={clue}/>
        }
        {noAssignedTrack && <InlineAlert alert={{
          id: "no-assigned-track",
          message: "No Assigned Track",
          variant: "warning",
        }}/>}
      </PageLayout>
      <PlayerPresenceAndIdling/>
      <HintNudgeModal/>
    </FadeContents>
    <ApplyCustomGameTheme/>
  </LoadingSpinner>;
};

export default InGame;
