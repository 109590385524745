import {useContext, useEffect, useState} from "react";
import GameContext from "../context/game-context";
import useCurrentClueLogic from "./useCurrentClueLogic";
import {
  compareTimestamps,
  newFirestoreTimeStamp, parseFirstoreTimestamp,
} from "../utilities/dates";
import useInterval from "./useInterval";
import {usePreviousValue} from "./usePreviousValue";
import {DELAY_HINT_NUDGE_FOR_SECONDS_AFTER_ENTERING_GAME} from "../config/config-options";
import useHintQuestionsFlow from "./useHintQuestionsFlow";

export default function useNotifyHintNudge() {
  const {game, team, isLoading} = useContext(GameContext);
  const {clue, lastAdvancedTimestamp, penaltiesFromHints} = useCurrentClueLogic(game, team);
  const {disableHintButton} = useHintQuestionsFlow();
  const [show, setShow] = useState(false);
  const [hasShownForCurrentClue, setHasShownForCurrentClue] = useState(false);
  const previousClue = usePreviousValue(clue);

  const hintNudgeInSeconds = isLoading ?
    999 : // guard against this triggering hint nudge if game isn't loaded
    game?.hintNudgeAfterMinutes * 60;

  // Close popup if hint flow is not open for new people
  useEffect(() => {
    if (show && disableHintButton) {
      setShow(false);
    }
  }, [disableHintButton]);

  useEffect(() => {
    if (
      previousClue &&
      previousClue?.id !== clue?.id
    ) {
      setShow(false);
      setHasShownForCurrentClue(false);
    }
  }, [clue]);

  useInterval(() => {
    const [mostRecentHintTaken] = penaltiesFromHints.slice(-1);
    const referenceTimestamp = mostRecentHintTaken?.createdAt ? mostRecentHintTaken.createdAt : lastAdvancedTimestamp;

    // Check if the hint nudge has already been shown
    if (!hasShownForCurrentClue) {
      // Check if the required amount of time has passed
      if (compareTimestamps(
        parseFirstoreTimestamp({
          seconds: referenceTimestamp.seconds + hintNudgeInSeconds,
          nanoseconds: referenceTimestamp.nanoseconds,
        }),
        newFirestoreTimeStamp(),
      ) === 1) {
        if (!disableHintButton) {
          setShow(true);
          setHasShownForCurrentClue(true);
        }
      }
    }
  }, 1000, DELAY_HINT_NUDGE_FOR_SECONDS_AFTER_ENTERING_GAME * 1000);

  return {
    show,
    hideModal: () => setShow(false),
  };
}
