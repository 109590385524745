import Team from "../../entities/team";
import firebase from "firebase/app";
import firebaseApp from "../firebase-app";

export default function writePresenceToTeam(userId, team) {
  if (!team instanceof Team) {
    throw new Error("Team required to write presence.");
  }

  if (!userId) {
    console.warn("No user id. Presence will not be written.");
    return;
  }

  // Create a reference to this user's specific status node.
  // This is where we will store data about being online/offline.
  var userStatusDatabaseRef = firebaseApp.database().ref(`game-presence/${team.gameId}/team/${team.id}/users/${userId}`);

  // We'll create two constants which we will write to
  // the Realtime database when this device is offline
  // or online.
  var isOfflineForDatabase = {
    state: "offline",
    last_changed: firebase.database.ServerValue.TIMESTAMP,
  };

  var isOnlineForDatabase = {
    state: "online",
    last_changed: firebase.database.ServerValue.TIMESTAMP,
  };


  // Create a reference to the special '.info/connected' path in
  // Realtime Database. This path returns `true` when connected
  // and `false` when disconnected.
  firebaseApp.database().ref(".info/connected").on("value", function (snapshot) {

    // If we're not currently connected, don't do anything.
    if (snapshot.val() == false) {
      return;
    }

    // If we are currently connected, then use the 'onDisconnect()'
    // method to add a set which will only trigger once this
    // client has disconnected by closing the app,
    // losing internet, or any other means.
    userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
      // The promise returned from .onDisconnect().set() will
      // resolve as soon as the server acknowledges the onDisconnect()
      // request, NOT once we've actually disconnected:
      // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

      // We can now safely set ourselves as 'online' knowing that the
      // server will mark us as offline once we lose connection.
      userStatusDatabaseRef.set(isOnlineForDatabase);
    });
  });

  // Return clean up function which will write user is offline and stop listening
  return () => {
    userStatusDatabaseRef.set(isOfflineForDatabase); // this logs offline if users goes to a different page ex: leaving team
    firebaseApp.database().ref(".info/connected").off();
  };
}
