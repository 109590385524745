import { TeamStatus } from "../entities/team";
import { useContext, useEffect } from "react";
import useCurrentClueLogic from "./useCurrentClueLogic";
import { finishTeam } from "../services/firebase";
import GameContext from "../context/game-context";

export default function useRecordFinishGameWhenCluesAreAnswered() {
  const { game, team } = useContext(GameContext);
  const {finishedAllClues, status} = useCurrentClueLogic(game, team);
  useEffect(() => {
    if (!team || !game) {
      return;
    }

    // If game finishes on last question, trigger the finish
    if (
      finishedAllClues &&
      status === TeamStatus.WRITE_TEAM_FINISH
    ) {
      finishTeam(team).catch(error => console.warn(error.message));
    }
  }, [game, team, finishedAllClues, status])
}
