import {useContext} from "react";
import GameContext from "../context/game-context";
import useInterval from "./useInterval";
import {recordPastTimeLimitOnClue} from "../services/firestore/teams";

export const useRecordTimeLimitHit = ({clue}) => {
  const {game, team} = useContext(GameContext);

  useInterval(() => {
    if (!clue || !game || !team) return;
    const timeLimitMinutes = clue.getTimeLimitMinutes(game);
    const alreadyRecordedPastTimeLimitOnClue = team.cluePastTimeLimitIds.includes(clue.id)

    if (timeLimitMinutes && !alreadyRecordedPastTimeLimitOnClue) {
      const secondsOnClue = team.getRawTimeSpentOnClue({game, clue});

      if (secondsOnClue + 1 >= timeLimitMinutes * 60) {
        // recordPastTimeLimitOnClue({
        //   team: team,
        //   clueId: clue.id,
        //   timeLimitMin: timeLimitMinutes,
        // });
      }
    }
  }, 1000);

}
