export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function renameProperty(
  data: Record<string, any>,
  oldName: string,
  newName: string,
): Record<string, any> {
  // Do nothing if the names are the same
  if (oldName === newName) {
    return data;
  }
  data[newName] = data[oldName];
  delete data[oldName];
  return data;
}

export function camelize(string: string): string {
  return string.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, "");
}

export function transformKeysToCamelCase(
  data: Record<string, any>
): Record<string, any> {
  const output: Record<string, any> = {};
  Object.keys(data).forEach((key) => {
    output[camelize(key)] = data[key];
  });
  return output;
}

export function basicCompare(a: string | number, b: string | number): number {
  if (a === b) return 0;
  else if (a < b) return -1;
  else return 1;
}
