import {useContext, useEffect, useState} from "react";
import {AlertType} from "../components/providers/app-alerts/AppAlerts";
import {AppAlertsContext} from "../context/app-alerts-context";
import GameContext from "../context/game-context";
import {markAlertAsSeen} from "../services/firestore/player-data";
import useFirebasePlayerData from "./useFirebasePlayerData";

export default function useNotifyOnClueMasterAlert() {
  const {team} = useContext(GameContext);
  const {popDismissibleAlert} = useContext(AppAlertsContext);
  const {playerData, isLoading: isLoadingPlayerData} = useFirebasePlayerData(team?.gameId, team?.id);
  const [lastShownAlertId, setLastShownAlertId] = useState(null);

  useEffect(() => {
    if (team && !isLoadingPlayerData) {
      const [lastAlert] = team?.alerts.slice(-1);
      const lastAlertAdditionalOptions = lastAlert?.additionalOptions || {};

      if (
        lastAlert &&
        lastShownAlertId !== lastAlert.id &&
        !playerData.hasSeenAlert(lastAlert.id)
      ) {
        setLastShownAlertId(lastAlert.id);
        markAlertAsSeen(team, lastAlert.id).then(() => {
          popDismissibleAlert(lastAlert.message, AlertType.WARNING, {
            title: lastAlert.title,
            showAsPlainText: true,
            ...lastAlertAdditionalOptions,
          });
        });
      }
    }
  });
}
