import {useContext, useEffect} from "react";
import {getCurrentUserID} from "../services/firebase";
import {compareTimestamps} from "../utilities/dates";
import {AlertType} from "../components/providers/app-alerts/AppAlerts";
import {usePreviousValue} from "./usePreviousValue";
import useArrivedOnPageTimestamp from "./useArrivedOnPageTimestamp";
import {AppAlertsContext} from "../context/app-alerts-context";
import GameContext from "../context/game-context";
import appContent from "../markdown/app-content";

export default function useNotifyOnContentRevealed(clue) {
  const {team} = useContext(GameContext);
  const arrivedAt = useArrivedOnPageTimestamp();
  const previousTeam = usePreviousValue(team);
  const {popDismissibleAlert} = useContext(AppAlertsContext);

  useEffect(() => {
    if (!clue || !team) {
      return;
    }

    const teamContentRevealed = team.contentRevealedForClue(clue);
    if (
      teamContentRevealed.length >= 1 &&
      teamContentRevealed.length !== previousTeam?.contentRevealedForClue(clue).length
    ) {
      const [lastContentRevealed] = teamContentRevealed.slice(-1);

      // Only show notification if the content was revealed by another player
      if (compareTimestamps(lastContentRevealed.addedAt, arrivedAt) <= 0) {
        if (lastContentRevealed.answeredBy !== getCurrentUserID()) {
          popDismissibleAlert(
            appContent.alerts.contentRevealedByPlayer(
              team.getTeamMemberName(lastContentRevealed.answeredBy)
            ),
            AlertType.SUCCESS
          );
        }
      }
    }
  });
}
