import {useContext, useEffect} from "react";
import {usePreviousValue} from "./usePreviousValue";
import {AppAlertsContext} from "../context/app-alerts-context";
import GameContext from "../context/game-context";
import useCurrentClueLogic from "./useCurrentClueLogic";
import {AlertType} from "../components/providers/app-alerts/AppAlerts";
import appContent from "../markdown/app-content";

export default function useNotifyWhenPushedToDifferentClue() {
  const {game, team} = useContext(GameContext);
  const {clue} = useCurrentClueLogic(game, team);
  const previousClue = usePreviousValue(clue);
  const {popDismissibleAlert} = useContext(AppAlertsContext);

  useEffect(() => {
    if (
      previousClue &&
      clue?.id !== previousClue.id &&
      !team.hasAnsweredClue(previousClue.id)
    ) {
      // Handle case where team was advanced due to a clue being disabled
      if (game.getClue(previousClue.id).disabled) {
        popDismissibleAlert(appContent.inGame.alerts.teamAdvancedFromDisabledClue, AlertType.WARNING);
      }

      // Handle case where team was advanced due to a time limit being exceeded
      if (team.teamHitTimeLimitOnClue(previousClue.id)) {
        popDismissibleAlert(appContent.inGame.alerts.teamAdvancedFromHittingTimeLimit, AlertType.WARNING);
      }
    }
  });
}
