import React, { useContext } from "react";
import PropTypes from 'prop-types';
import { Button, Modal } from "react-bootstrap";
import appContent from "../../markdown/app-content";
import { setTeamQuit } from "../../services/firestore/teams";
import GameContext from "../../context/game-context";
import { AppAlertsContext } from "../../context/app-alerts-context";
import { AlertType } from "../providers/app-alerts/AppAlerts";

const TeamIdlingModal = ({onCanceledQuit, ...rest}) => {
  const {team} = useContext(GameContext);
  const {popAlert, popError} = useContext(AppAlertsContext);

  const onCancelQuit = async () => {
    try {
      await setTeamQuit(team, false);
      popAlert(appContent.modals.teamQuit.messageWhenCancelingQuitting, AlertType.SUCCESS);
      onCanceledQuit();
    } catch (e) {
      popError(e.message);
    }
  }

  return <>
    <Modal {...rest} onHide={() => {}}>
      <Modal.Header>
        <Modal.Title>{appContent.modals.teamQuit.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {appContent.modals.teamQuit.markdown}
      </Modal.Body>

      <Modal.Footer>
        <Button block variant="info" onClick={onCancelQuit}>
          {appContent.modals.teamQuit.buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  </>
}

TeamIdlingModal.protoTypes = {
  show: PropTypes.bool.isRequired,
  onCanceledQuit: PropTypes.bool.isRequired,
};

export default TeamIdlingModal;
