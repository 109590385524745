import React, {useContext, useEffect, useState} from "react";
import TeamIdlingModal from "../../../components/modals/TeamIdlingModal";
import useFirebaseTeamPresence from "../../../hooks/useFirebaseTeamPresence";
import TeamQuitModal from "../../../components/modals/TeamQuitModal";
import {SHOW_IDLE_AFTER_SECONDS} from "../../../config/config-options";
import GameContext from "../../../context/game-context";

const PlayerPresenceAndIdling = () => {
  const {teamHasQuit, lastActivityInSecondsAgo} = useFirebaseTeamPresence();
  const {team} = useContext(GameContext);
  const [showTeamIdlingModal, setShowTeamIdlingModal] = useState(false);
  const [showTeamQuitModal, setShowTeamQuitModal] = useState(false);

  useEffect(() => {
    if (teamHasQuit) {
      setShowTeamIdlingModal(false);
      setShowTeamQuitModal(true);
    } else if (
      !team?.isFinished &&
      lastActivityInSecondsAgo > SHOW_IDLE_AFTER_SECONDS
    ) {
      setShowTeamIdlingModal(true);
    }
  }, [teamHasQuit, lastActivityInSecondsAgo]);


  return <>
    <TeamIdlingModal show={showTeamIdlingModal} onHide={() => setShowTeamIdlingModal(false)}/>
    <TeamQuitModal show={showTeamQuitModal} onCanceledQuit={() => setShowTeamQuitModal(false)}/>
  </>;
};

export default PlayerPresenceAndIdling;
