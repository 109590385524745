import {useContext, useEffect, useState} from "react";
import GameContext from "../context/game-context";
import writePresenceToTeam from "../services/realtime-db/presence";
import {getCurrentUserID} from "../services/firebase";
import {getTeamIdleTime} from "../services/firestore/presence";
import {secondsSinceTimestamp} from "../utilities/dates";

export default function useFirebaseTeamPresence() {
  const [lastActivityInSecondsAgo, setLastActivityInSecondsAgo] = useState(0);
  const [hasCheckedPresence, setHasCheckedPresence] = useState(false);
  const {team} = useContext(GameContext);

  useEffect(() => {
    if (team) {
      if (!hasCheckedPresence) {
        getTeamIdleTime(team).then(teamIdleTime => {
          setLastActivityInSecondsAgo(teamIdleTime ? secondsSinceTimestamp(teamIdleTime) : 0);
          setHasCheckedPresence(true);
        });
      }
    }
  }, [hasCheckedPresence, team]);

  useEffect(() => {
    if (hasCheckedPresence) {
      return writePresenceToTeam(getCurrentUserID(), team);
    }
  }, [hasCheckedPresence]);

  return {
    lastActivityInSecondsAgo,
    teamHasQuit: team?.quit,
  };
}
