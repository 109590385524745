import {useCallback, useContext, useEffect, useRef} from "react";
import GameContext from "../context/game-context";
import {getRandomInt} from "../../functions/src/helper-functions";
import {recordPastDisabledClue} from "../services/firestore/teams";

export const useRecordPastDisabledClue = () => {
  const {game, team} = useContext(GameContext);
  const currentTeam = useRef(team);
  currentTeam.current = team;

  const handleUpdate = () => {
    const {teamSkippedOverDisabledClues, ...rest} = currentTeam.current.findProgressForTeam(game);
    teamSkippedOverDisabledClues.forEach((clue) => {
      if (!currentTeam.current.cluePastDisabled[clue.id]) {
        try {
          recordPastDisabledClue({team: currentTeam.current, clueId: clue.id});
        } catch (e) {
          console.error("recordPastDisabledClue", e); // should not break app
        }
      }
    });
  }

  useEffect(() => {
    if (!team) {
      return;
    }

    // Add a random delay so all players don't try to write the status at the same time.
    const t = setTimeout(() => handleUpdate(), getRandomInt(250, 900));
    return () => clearTimeout(t);
  }, [team, handleUpdate]);
}