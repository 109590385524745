import {useContext, useEffect, useState} from "react";
import GameContext from "../context/game-context";
import {recordClueStart} from "../services/firestore/teams";
import useCurrentClueLogic from "./useCurrentClueLogic";
import {TeamStatus} from "../entities/team";

const canRecordStart = ({status, showWrapItUp}) => {
  return status !== TeamStatus.NOT_STARTED ||
    status !== TeamStatus.ON_INTERSTITIAL ||
    !showWrapItUp
    ;
}

export const useRecordClueStartTimes = () => {
  const {game, team} = useContext(GameContext);
  const {showWrapItUp, clue, status} = useCurrentClueLogic(game, team);
  const [startedSavingClue, setStartedSavingClue] = useState(null);

  useEffect(() => {
    if (startedSavingClue && startedSavingClue !== clue?.id) {
      setStartedSavingClue(null);
    }
  }, [clue, startedSavingClue]);

  useEffect(() => {
    if (!team || !clue) {
      return;
    }

    if (
      canRecordStart({status, showWrapItUp}) &&
      !startedSavingClue && !team.getHasRecordedClueStart(clue)
    ) {
      setStartedSavingClue(clue.id);
      (async () => {
        try {
          await recordClueStart({
            team: team,
            clueId: clue.id,
          });
        } catch (e) {
          // do nothing. Another user likely wrote it first and firestore's security rules errored it out.
        }
      })();
    }
  }, [showWrapItUp, startedSavingClue, team, clue, status]);
}