import React, {useContext} from "react";
import GameContext from "../../../../context/game-context";
import {Markdown} from "../../../../components/components";
import {GameOptions} from "../../../../entities/game";

const WrappedUp = () => {
  const {game} = useContext(GameContext);

  return <div>
    <Markdown
      className={"clue-view"}
      allowDangerousHtml
    >
      {game.getOption(GameOptions.WRAP_IT_UP_CONTENT)}
    </Markdown>
  </div>
}

export default WrappedUp;
