import {Button, Modal} from "react-bootstrap";
import React, {useContext} from "react";
import appContent from "../../markdown/app-content";
import {AppAlertsContext} from "../../context/app-alerts-context";
import {AlertType} from "../providers/app-alerts/AppAlerts";
import {setTeamQuit} from "../../services/firestore/teams";
import GameContext from "../../context/game-context";
import {LinkNewTab} from "../components";
import {GameOptions} from "../../entities/game";

const TeamIdlingModal = ({onHide, ...rest}) => {
  const {game, team} = useContext(GameContext);
  const {popAlert, popError} = useContext(AppAlertsContext);

  const handleExitGame = async () => {
    try {
      await setTeamQuit(team, true);
      onHide();
    } catch (e) {
      popError(e.message);
    }
  };

  const handleStillPlaying = () => {
    onHide();
    popAlert(appContent.modals.teamIdling.messageOnStillPlaying, AlertType.WARNING);
  };

  return <>
    <Modal onHide={() => {
    }} {...rest}>
      <Modal.Header>
        <Modal.Title>{appContent.modals.teamIdling.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {appContent.modals.teamIdling.markdown}
      </Modal.Body>

      <Modal.Footer>
        {!game?.getOption(GameOptions.DISABLE_CLUEMASTER_CONTACT) && (
          <LinkNewTab className="btn btn-block btn-outline-info text-info" role="button" to="/game/contact-cluemaster">
            {appContent.modals.teamIdling.actionButtons.contactClueMaster}
          </LinkNewTab>
        )}
        <Button block variant="outline-info" onClick={handleExitGame}>
          {appContent.modals.teamIdling.actionButtons.exitGameText}
        </Button>
        <Button block variant="info" onClick={handleStillPlaying}>
          {appContent.modals.teamIdling.actionButtons.stillPlayingText}
        </Button>
      </Modal.Footer>
    </Modal>
  </>;
};

export default TeamIdlingModal;
