import GameContext from "../context/game-context";
import {useContext, useEffect} from "react";
import {navigate} from "gatsby";

export const useWaitingRoomGuard = () => {
  const {game, team} = useContext(GameContext);

  useEffect(() => {
    if (!team?.getShouldBePastWaitingRoom(game)) {
      const params = new URLSearchParams({gameId: game?.gameId});
      void navigate(`/game/waiting-area?${params.toString()}`);
    }
  }, [game, team]);
}
